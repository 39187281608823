<template>
  <div class="">
    <div class="w-full flex text-90 text-c14 font-c6 text-left">
      <div class="w-1/4">
        INFOS COMMERCIALES
      </div>
      <div class="w-1/6 ml-4">
        MAT.
      </div>
      <div class="w-1/5 ml-6">
        FABRICATION
      </div>
      <div class="w-68 ml-4">
        SC
      </div>
      <div class="w-68 ml-4">
        IND.
      </div>
      <div class="w-1/6 ml-4">
        T. DUR
      </div>
      <div class="w-68 ml-4">
        T. AR
      </div>

      <div class="w-68 ml-6">
        DIAM
      </div>

      <div class="w-1/6 ml-6">
        PRIX DE LA PAIRE
      </div>
      <div class="w-10 ml-4" />
    </div>

    <div class="flex justify-center mt-12" v-if="charge && allVerre.length === 0">
      <easy-spinner
          type="dots"
          size="30"
      />
    </div>

    <div class="mt-10 mb-10 text-90 text-c16 text-center" v-if="tracker.length === 0 && this.search !== null">
      Aucun verre correspondant à votre recherche
    </div>

    <div
      v-for="(item, index) in tracker"
      :key="index"
      class="w-full flex items-center text-c14 font-c4 text-left mt-6 item pt-6 cursor-pointer"
      @click="selected(item)"
    >
      <div class="w-1/4">
        <div class="text-c14 font-c7">{{ item.glassType }}</div>
        <div class="text-c14 font-c7">{{ item.ref }}</div>
        <div class="text-c14">
          <span v-if="item.glassDetails !== undefined && item.glassDetails !== null">
            <span v-if="item.glassDetails.glassGen !== undefined">{{ item.glassDetails.glassGen }}</span>
            <span v-if="item.glassDetails.glassGen === undefined || item.glassDetails.glassGen === null">-</span>
          </span>
        </div>
      </div>

      <div class="w-1/6 ml-4">
        {{item.glassMaterial}}
      </div>

      <div class="w-1/5 ml-4">
        <span v-if="item.meta !== null && item.meta.fabrication !== undefined">
          {{item.meta.fabrication}}
        </span>
        <span v-if="item.meta === undefined || item.meta === null || item.meta.fabrication === undefined">
          -
        </span>
      </div>

      <div class="w-68 ml-4">
         <span v-if="item.glassDetails !== null">
          {{item.glassDetails.underCoat}}
        </span>
        <span v-if="!item.glassDetails">
          -
        </span>
      </div>

      <div class="w-68 ml-4">
        {{item.indice}}
      </div>

      <div class="w-1/6 ml-4">
       <span v-if="item.glassDetails !== null">
          {{item.glassDetails.treatmentDur}}
        </span>
        <span v-if="!item.glassDetails">
          -
        </span>
      </div>

      <div class="w-68 ml-4">
        <span v-if="item.glassDetails !== null">
          {{item.glassDetails.treatmentAr}}
        </span>
        <span v-if="!item.glassDetails">
          -
        </span>
      </div>

      <div class="w-68 ml-6">
        <span v-if="item.glassDetails !== null">
          {{item.glassDetails.diameterMin}}-{{item.glassDetails.diameterMax}}
        </span>
        <span v-if="!item.glassDetails">
          -
        </span>
      </div>

      <div class="w-1/6 ml-6">
       <span v-if="item.glassDetails !== null">
         <span v-if="item.prices.length > 0" :class="{'hassecond': item.hasSecond === true}">
            {{ (item.prices[0].amount ).toLocaleString()}}
         </span>
        </span>
        <span v-if="!item.glassDetails">
          -
        </span>
      </div>

      <div class="w-10 ml-4">
        <icon
          :data="icons.add"
          v-if="controleFiltre() === 'ok'"
          height="30"
          width="30"
          class="icone"
          original
        />
      </div>
    </div>

    <div class="flex mt-10 justify-end pb-16 pr-10">
      <div class="w-1/3 flex items-center justify-end">
        <div class="text-c16 font-c6">
          Page {{ page }} sur {{ totalPage }}
        </div>
        <icon
            :data="icons.prev"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            @click="prev"
            original
        />
        <icon
            :data="icons.next"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            @click="next"
            original
        />
      </div>
    </div>
  </div>
</template>

<script>
import add from '../../../assets/icons/addGreen.svg'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"
import prev from '../../../assets/icons/prev.svg'
import next from '../../../assets/icons/next.svg'

export default {
  name: "Index",
  components: {
  },

  props: {
    type: {
      type: String,
      default: null
    },
    search: {
      type: Object,
      default: null
    },
    recherche: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      icons: {
        add,
        prev,
        next
      },
      allVerre: [],
      activeDetail: false,
      selectedItem: {
        oeil: ''
      },
      charge: true,
      coupon: null,

      page: 1,
      limite: 20,
      total: 0,
      totalPage: 0,
      allVerrePaginate: []
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.allVerrePaginate

      if (this.search !== null) {
        if (this.allVerre.length > 0){


          retour = this.allVerre
          // filtre on Glass Family
          if (this.search.famille !== undefined && this.search.famille !== ''){
            let type = 'PROGRESSIVE'
            if (this.search.famille === 'Dégressif'){
              type = 'DEGRESSIVE'
            }
            if (this.search.famille === 'Double foyer'){
              type = 'BIFOCAL'
            }
            if (this.search.famille === 'Simple Foyer'){
              type = 'SINGLE_FOCUS'
            }
            retour = retour.filter((item) => {
              if (item.glassType !== undefined && (item.glassType.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
                return item
              }
            })
          }

          if (this.recherche !== null && this.recherche !== '') {
            const type = this.recherche.toLowerCase()
            retour = retour.filter((item) => {
              if (item.name && item.ref) {
                if ((item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
                    (item.ref.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
                  return item
                }
              }
            })
          }

          //Sous Couche
          if (this.search.sousCouche !== undefined){
            let type = this.search.sousCouche

            retour = retour.filter((item) => {
              if (item.glassDetails !== null) {
                if (item.glassDetails !== undefined && (item.glassDetails.underCoat.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
                  return item
                }
              }
            })
          }

          //DUR
          if (this.search.dur !== undefined){
            let type = this.search.dur

            retour = retour.filter((item) => {
              if (item.glassDetails !== undefined && item.glassDetails !== null && item.glassDetails.treatmentDur !== null ){
                if ((item.glassDetails.treatmentDur.replace(/ /g, "").normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.replace(/ /g, "").toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
                  return item
                }
              }
            })
          }

          //AR
          if (this.search.ar !== undefined){
            let type = this.search.ar

            retour = retour.filter((item) => {
              if (item.glassDetails !== undefined && item.glassDetails !== null && item.glassDetails.treatmentAr !== null ) {
                if ((item.glassDetails.treatmentAr.replace(/ /g, "").normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.replace(/ /g, "").toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
                  return item
                }
              }
            })
          }

          //CYLINDRE
          if (this.search.cylindre !== undefined  && this.search.cylindre !== ''){
            let type = parseFloat(this.search.cylindre.replace(",", "."))
            retour = retour.filter((item) => {
              if (item.glassDetails !== undefined && item.glassDetails !== null && item.glassDetails.cylinderMax !== undefined ) {
                if (parseFloat(item.glassDetails.cylinderMax) >= type && parseFloat(item.glassDetails.cylinderMin) <= type) {
                  return item
                }
              }
            })
          }

          //DIAMETRE
          if (this.search.diametre !== undefined  && this.search.diametre !== ''){
            let type = parseFloat(this.search.diametre.replace(",", "."))
            retour = retour.filter((item) => {
              if (item.glassDetails !== undefined && item.glassDetails !== null && item.glassDetails.diameterMax !== undefined ) {
                if (parseFloat(item.glassDetails.diameterMax) >= type && parseFloat(item.glassDetails.diameterMin) <= type) {
                  return item
                }
              }
            })
          }

          //ADD
          if (this.search.addition !== undefined  && this.search.addition !== ''){
            let type = parseFloat(this.search.addition.replace(",", "."))
            retour = retour.filter((item) => {
              if (item.glassDetails !== undefined && item.glassDetails !== null && item.glassDetails.additionMax !== undefined ) {
                if (parseFloat(item.glassDetails.additionMax.replace(",", ".")) >= type && parseFloat(item.glassDetails.additionMin.replace(",", ".")) <= type) {
                  return item
                }
              }
            })
          }

          //SPHERE
          if (this.search.sphere !== undefined  && this.search.sphere !== ''){
            let type = parseFloat(this.search.sphere.replace(",", "."))
            retour = retour.filter((item) => {
              if (item.glassDetails !== undefined && item.glassDetails !== null && item.glassDetails.sphereMax !== undefined ) {
                if (parseFloat(item.glassDetails.sphereMax) >= type && parseFloat(item.glassDetails.sphereMin) <= type) {
                  return item
                }
              }
            })
          }

          //FABRICATION
          if (this.search.fabrication !== undefined  && this.search.fabrication !== ''){
            let type = this.search.fabrication
            let secondType = ''

            if (this.search.fabrication === 'TRADITIONELLE') {
              secondType = 'TRADITIONNELLE'
            }

            if (this.search.fabrication === 'FREE FORME') {
              secondType = 'FREE FORM'
            }

            retour = retour.filter((item) => {
              if (item.meta !== null && item.meta.fabrication !== undefined) {
                if (item.meta.fabrication.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === type.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() || item.meta.fabrication.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === secondType.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()) {
                  return item
                }
              }
            })
          }

          this.sendTotal(retour.length)
        } else {
          this.searchOption(true)
        }
      }
      this.$emit('totalVerre', this.total)
      return retour
    }
  },

  created () {
    let coupon = this.$store.getters.coupon
    if (coupon !== null) {
      this.coupon = coupon
    } else {
      const devis = this.$store.getters.update
      if (devis){
        this.coupon = devis.coupon
      }
    }

    this.allProductPaginate()
    this.allProduct()
  },

  methods: {
    sendTotal (value) {
      this.total = value
    },

    searchOption (value) {
      this.charge = value
    },

    retourDetail (answer) {
      this.activeDetail = answer
    },

    prev () {
      if (this.page > 1) {
        this.page = this.page - 1
        this.allProductPaginate()
      }
    },

    next () {
      if (this.totalPage > this.page) {
        this.page = this.page + 1
        this.allProductPaginate()
      }
    },

    selected (item) {
      if (this.controleFiltre() === 'ok' ) {
        this.selectedItem.oeil = this.type
        this.selectedItem.product = item
        this.selectedItem.details = this.search
        this.selectedItem.prix = item.prices[0].amount / 2
        this.selectedItem.qte = 1
        // this.$store.dispatch('saveTemporalItem', this.selectedItem)
        this.$store.dispatch('addToBasket', this.selectedItem)

        //vider les donnée inutiles
        // const search = this.search
        // search.sphere = null
        // search.addition = null
        // search.diametre = null
        // search.cylindre = null
        // this.$store.dispatch('saveFiltre', search)

        this.$emit('fin', false)
      } else {
        this.$emit('alert', true)
      }
    },

    controleFiltre () {
      if (this.search !== null && this.search.fabrication !== undefined
          && this.search.fabrication !== '' && this.search.color !== undefined
          && this.search.sphere !== undefined  && this.search.sphere !== ''
          && this.search.addition !== undefined  && this.search.addition !== ''
          && this.search.diametre !== undefined  && this.search.diametre !== ''
          && this.search.cylindre !== undefined  && this.search.cylindre !== ''
          && this.search.ar !== undefined && this.search.dur !== undefined
          && this.search.sousCouche !== undefined
          && this.search.famille !== undefined && this.search.famille !== ''
          && this.search.axe !== undefined && this.search.axe !== '') {
        return 'ok'
      } else {
        return 'non'
      }
    },

    allProduct () {
      const standard = apiroutes.baseURL + apiroutes.liteproduct + '?type=GLASS'
      let request = standard

      // AFFICHAGE DES PRODUITS EN FONCTION DU TYPE DE VENTE
      if (this.coupon !== null) {
        request = standard + '&sellType=SECOND'
      } else {
        request = standard + '&sellType=PRINCIPAL'
      }

      http.get(request)
          .then(response => {
            // console.log(response)
            this.allVerre = response.reverse()
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    allProductPaginate () {
      this.charge = true
      const body = {
        page: this.page,
        limit: this.limite,
        type: "GLASS",
        sellType: 'PRINCIPAL'
      }

      if (this.coupon !== null) {
       body.sellType = 'SECOND'
      }

      http.post(apiroutes.baseURL + apiroutes.getProductParginate, body)
          .then(response => {
            // console.log('paginer affichage')
            // console.log(response)
            this.total = response.total
            this.allVerrePaginate = response.data
            const page = parseInt(this.total)/parseInt(this.limite)

            if (page > parseInt(page)) {
              this.totalPage = parseInt(page) + 1
            } else {
              this.totalPage = parseInt(page)
            }
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  border-top: 1px solid #DDDDDD;
}
.item:hover{
  color: #3521B5;
}
.hassecond{
  color: red;
}
</style>

<template>
  <div class="pb-12">

    <div class="text-c18 text-left mt-4 ml-4 mb-4">
      <span class="text-c34 font-c7 mr-2">{{ tracker.length }}</span> Services
    </div>

    <div>
      <div class="w-2/4 ml-6">
        <inputo :icon="icons.search" @info="retourSearch" placeholder="Rechercher" />
      </div>
    </div>

    <div class="flex justify-center mt-12" v-if="charge && allVerre.length === 0">
      <easy-spinner
          type="dots"
          size="30"
      />
    </div>

    <div
      v-for="(item, index) in tracker"
      :key="index"
      class="mt-10"
      @click="selected(item)"
    >
      <div class="">
        <div class="w-full flex text-c14 font-c4 text-left items-center item cursor-pointer">
          <div class="w-5/6 flex items-center pl-6">
            <icon
                :data="icons.prestation"
                height="50"
                width="50"
                class="icone cursor-pointer"
                original
            />
            <div
                class="ml-6"
            >
              <div class="text-c18 font-c6 tit">
                {{item.name}}
              </div>

              <div class="text-c12 mt-2">
                <span v-for="(i, index) in item.prices" :key="index">
                  <span class=""> {{i.amount}} Fr </span>
                </span>
              </div>
            </div>
          </div>

          <div class="w-1/6 flex justify-end pr-10">
            <icon
                :data="icons.add"
                height="30"
                width="30"
                class="icone cursor-pointer"
                original
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import add from '../../../assets/icons/addGreen.svg'
import prestation from '../../../assets/icons/prestation.svg'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"
import inputo from '../../helper/form/input'
import search from '../../../assets/icons/search.svg'

export default {
  name: "Index",
  components: {
    inputo
  },

  data () {
    return {
      icons: {
        add,
        prestation,
        search
      },
      allVerre: [],
      charge: true,
      response: {
        search: null
      }
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.allVerre
      if (this.response.search !== null && this.response.search !== '') {
        const type = this.response.search.toLowerCase()
        retour = retour.filter((item) => {
          if ((item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
            return item
          }
        })
      }

      retour = retour.filter((item) => {
        if (item.prestationType === 'SERVICE'){
          return item
        }
      })

      return retour
    }
  },

  created () {
    this.allProduct()
  },

  methods: {
    allProduct () {
      http.get(apiroutes.baseURL + apiroutes.getProduct + '?type=SERVICE')
          .then(response => {
            // console.log(response)
            this.allVerre = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourSearch (answer) {
      this.response.search = answer
    },

    selected (item) {
      item.prix = item.prices[0].amount
      item.qte = 1
      this.$store.dispatch('addToBasket', item)
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  .tit {
    color: #3521B5;
  }
}
</style>

<template>
  <div class="">
    <confirm :activation="activeConfirm" v-if="activeConfirm" :message="message" @oga="retourConfirm" />
    <alert
        v-if="activeAlert"
        :activation="activeAlert"
        @oga="retourAlert"
    />
    <verrePrice
        v-if="activePrice"
        :activation="activePrice"
        @oga="retourPrice"
    />
    <productDetail
        v-if="activeDetail"
        :activation="activeDetail"
        @oga="activeDetail = false"
    />
    <div>
      <div class="bg-white p-10 pr-16 pb-0 bord">
        <div class="w-2/3 flex text-left text-c34 items-center font-c7">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retourBack"
          />
          <div class="ml-4">
            Panier vente
          </div>
        </div>

        <div class="mt-10 flex">
            <steep
                :option="allSteep"
                :value="valueSteep"
                :separator="2"
                @info="retourSteep"
            />
        </div>
      </div>

      <div class="mt-6 flex p-12 pt-6">
        <div class="w-3/4">
          <div class="bloc bg-white rounded-6 p-5">
            <verre
                v-if="valueSteep === 'Verres'"
                @oga="activePrice = true"
                @alert="retourAlert"
            />
            <monture
                v-if="valueSteep === 'Montures'"
                @oga="activeDetail = true"
            />
            <accessoire
                v-if="valueSteep === 'Accessoires'"
                @oga="activeDetail = true"
            />
            <prestation
                v-if="valueSteep === 'Services'"
            />
            <reparation
                v-if="valueSteep === 'Réparations'"
            />
          </div>
        </div>

        <div class="w-1/4 ml-3">
          <div class="bloc bg-white rounded-6 p-5">
            <reccapitulatif
                :remover="sendValue"
                @oga="activeConfirm = true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import add from '../../assets/icons/add.svg'
import steep from '../../component/helper/form/steepSelect'
import verre from '../../component/cdevs/panier/verre'
import monture from '../../component/cdevs/panier/monture'
import reparation from '../../component/cdevs/panier/reparation'
import accessoire from '../../component/cdevs/panier/accessoire'
import prestation from '../../component/cdevs/panier/prestation'
import reccapitulatif from '../../component/cdevs/panier/reccapitulatif'
import verrePrice from '../../component/popup/verrePrice'
import productDetail from '../../component/popup/detailProduct'
import alert from '../../component/popup/alertFiltrage'
import confirm from '../../component/popup/confirm'

export default {
  name: "Index",

  components: {
    steep,
    verre,
    reccapitulatif,
    monture,
    accessoire,
    prestation,
    verrePrice,
    productDetail,
    alert,
    reparation,
    confirm
  },

  data () {
    return {
      icons: {
        back,
        add
      },
      allSteep: ['Verres', 'Montures', 'Accessoires', 'Services', 'Réparations'],
      valueSteep: 'Verres',
      activePrice: false,
      activeDetail: false,
      activeAlert: false,
      activeConfirm: false,
      message: 'Êtes vous sûr de vouloir retirer cet article?',
      deleteValue: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      return this.deleteValue
    },
  },

  created () {
  },

  methods: {
    retourBack () {
      window.history.back()
    },

    retourConfirm (answer) {
     if (answer) {
        this.deleteValue = new Date().getTime().toString()
     }
     this.activeConfirm = false
    },

    retourSteep (answer) {
      this.valueSteep = answer
    },
    retourPrice (answer) {
      this.activePrice = answer
    },
    retourAlert (answer) {
      this.activeAlert = answer
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
.alert {
  background: #FFF0F0;
  border-radius: 5.10135px;
}
.bord{
  border-bottom: 1px solid #DDDDDD;
}
</style>

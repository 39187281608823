<template>
  <div class="pt-6">
    <div class="text-c18 text-left">
      <span class="text-c34 font-c7 mr-2">{{ total }}</span> Montures
    </div>

    <div class="flex mt-10">
      <div class="w-4/5 flex">
        <div class="w-1/5">
          <multiselect :option="optionMarque" v-if="optionMarque.length > 0" @info="retourMarque" value-t="Marques" size="13px"/>
        </div>

        <div class="w-1/5 ml-6">
          <multiselect :option="optionGamme" v-if="optionGamme.length > 0" @info="retourGamme" value-t="Gammes" size="13px"/>
        </div>

        <div class="w-3/5 ml-6 flex items-center">
          <inputo :icon="icons.search" @info="retourSearch" placeholder="Rechercher" class="w-full"/>
          <div class="ml-2">
            <easy-spinner
                type="dots"
                size="30"
                v-if="chargeSearch"
            />
          </div>

        </div>
      </div>
    </div>

    <div class="flex justify-center mt-12" v-if="charge">
      <easy-spinner
          type="dots"
          size="30"
      />
    </div>

    <div
      v-for="(item, index) in tracker"
      :key="index"
      class="mt-10"
      @click="selected(item)"
    >
      <div class="">
        <div class="w-full flex text-c14 font-c4 text-left items-center item cursor-pointer">
          <div class="w-1/2 flex items-center">
            <img
                src="../../../assets/images/jpg/lunette.jpg"
                alt=""
                class="w-1/6"
            >
            <div
                class="ml-6"
            >
              <div class="text-c15 font-c6 tit">
                {{item.name}}
              </div>

              <div class="text-c11 mt-2">
                <span class="text-90">Qté dispo :</span>
                <span v-if="item.quantity"> {{ item.quantity.toLocaleString() }} </span>
                <span v-if="!item.quantity"> - </span>

                <span v-for="(i, index) in item.prices" :key="index">
              <span class="ml-4"> {{i.amount}} Fr </span>
              <span class="text-90 ml-2 mr-2"> |</span>
            </span>
              </div>
            </div>
          </div>

          <div class="w-1/5 ml-2 text-left">
            {{ item.brands[0].label }}
          </div>

          <div class="w-1/5 ml-2 text-left">
            {{ item.ranges[0].label }}
          </div>

          <div class="w-48 ml-2">
            <icon
                :data="icons.add"
                height="30"
                width="30"
                class="icone cursor-pointer"
                original
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex mt-10 justify-end pb-16 pr-10">
      <div class="w-1/3 flex items-center justify-end">
        <div class="text-c16 font-c6">
          Page {{ page }} sur {{ totalPage }}
        </div>
        <icon
            :data="icons.prev"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            @click="prev"
            original
        />
        <icon
            :data="icons.next"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            @click="next"
            original
        />
      </div>
    </div>
  </div>
</template>

<script>
import add from '../../../assets/icons/addGreen.svg'
import prev from '../../../assets/icons/prev.svg'
import next from '../../../assets/icons/next.svg'
import search from '../../../assets/icons/search.svg'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"
import multiselect from '../../helper/form/multiselect'
import inputo from '../../helper/form/input'

export default {
  name: "Index",
  components: {
    multiselect,
    inputo
  },

  data () {
    return {
      icons: {
        add,
        search,
        next,
        prev
      },
      allVerre: [],
      charge: true,
      chargeSearch: false,
      optionGamme: [],
      optionMarque: [],
      selectedItem: {},
      allVerrePaginate: [],
      response: {
        marque: null,
        gamme: null,
        search: null
      },
      page: 1,
      limite: 20,
      total: 0,
      totalPage: 0
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.allVerrePaginate

      if (this.response.search !== null &&  this.response.search !== '' ||
          this.response.marque !== null && this.response.marque !== 'Marques' && this.response.marque !== 'Toutes ...' ||
          this.response.gamme !== null && this.response.gamme !== 'Gammes' && this.response.gamme !== 'Toutes ...') {

        if (this.allVerre.length > 0) {
          this.searchOption(false)
          retour = this.allVerre
          if (this.response.search !== null && this.response.search !== '') {
            const type = this.response.search.toLowerCase()
            retour = retour.filter((item) => {
              if ((item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
                return item
              }
            })
          }
            //Marque
            if (this.response.marque !== null && this.response.marque !== 'Marques' && this.response.marque !== 'Toutes ...') {
              retour = retour.filter((item) => {
                for(let i in item.brands) {
                  if (item.brands[i].label === this.response.marque) {
                    return item
                  }
                }

              })
            }

          //GAMME
          if (this.response.gamme !== null && this.response.gamme !== 'Gammes' && this.response.gamme !== 'Toutes ...') {
            retour = retour.filter((item) => {
              for(let i in item.ranges) {
                if (item.ranges[i].label === this.response.gamme) {
                  return item
                }
              }

            })
          }
          this.sendTotal(retour.length)
        } else {
          this.searchOption(true)
        }
      }
      return retour
    }
  },

    created () {
      this.allProductPaginate()
      this.allProduct()
      this.allMarques()
      this.allGammes()
    },

    methods: {
      sendTotal (value) {
        this.total = value
      },

      searchOption (value) {
        this.chargeSearch = value
      },

      retourMarque (answer) {
        if (answer !== '' && answer !== null){
          this.response.marque = answer
        }
      },

      prev () {
        if (this.page > 1) {
          this.page = this.page - 1
          this.allProductPaginate()
        }
      },

      next () {
        if (this.totalPage > this.page) {
          this.page = this.page + 1
          this.allProductPaginate()
        }
      },

      retourGamme (answer) {
        if (answer !== 'Gammes' && answer !== null){
          this.response.gamme = answer
        }
      },

      retourSearch (answer) {
        if (answer !== 'Marques' && answer !== null){
          this.response.search = answer
        }
      },

      allProduct () {
        http.get(apiroutes.baseURL + apiroutes.liteproduct + '?type=FRAMES')
            .then(response => {
              // console.log('les montures')
              // console.log(response)
              this.allVerre = response
              this.total = response.length
              this.charge = false
            })
            .catch(error => {
              this.charge = false
              console.log(error)
            })
      },

      allProductPaginate () {
        this.charge = true
        http.post(apiroutes.baseURL + apiroutes.getProductParginate, {
          "page": this.page,
          "limit": this.limite,
          "type": "FRAMES"
        })
            .then(response => {
              // console.log('paginer affichage')
              // console.log(response)
              this.total = response.total
              this.allVerrePaginate = response.data
              const page = parseInt(this.total)/parseInt(this.limite)

              if (page > parseInt(page)) {
                this.totalPage = parseInt(page) + 1
              } else {
                this.totalPage = parseInt(page)
              }
              this.charge = false
            })
            .catch(error => {
              this.charge = false
              console.log(error)
            })
      },

      allGammes () {
        http.get(apiroutes.baseURL + apiroutes.allGamme)
            .then(response => {
              let tab = ['Toutes ...']
              for (let item in response) {
                tab.push(response[item].label)
              }
              this.optionGamme = tab
            })
            .catch(error => {
              this.charge = false
              console.log(error)
            })
      },

      allMarques () {
        http.get(apiroutes.baseURL + apiroutes.allMarque)
            .then(response => {
              let tab = ['Toutes ...']
              for (let item in response) {
                tab.push(response[item].label)
              }
              this.optionMarque = tab
            })
            .catch(error => {
              this.charge = false
              console.log(error)
            })
      },

      selected (item) {
        this.selectedItem = item
        this.$store.dispatch('saveTemporalItem', this.selectedItem)
        this.$emit('oga', true)
      },
    },
  }
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  .tit {
    color: #3521B5;
  }
}
</style>

<template>
  <div>
    <div class="mt-4 flex p-6 pt-4">
      <div class="w-3/4">
        <div
          class="flex justify-center mt-12"
          v-if="response === null"
        >
          <easy-spinner
            type="dots"
            size="40"
          />
        </div>

        <div v-if="response !== null">
          <div class="flex">
            <div class="w-1/6">
              <div class="text-c14 text-82">
                SPH
              </div>
              <inputo
                type="number"
                :value-t="response.sphere"
                background="#FBFBFB"
                @info="retourSphere"
                height="40px"
                radius="4.68802px"
                class="mt-3"
              />
            </div>

            <div class="w-1/6 ml-4">
              <div class="text-c14 text-82">
                CYL
              </div>
              <inputo
                type="number"
                background="#FBFBFB"
                :value-t="response.cylindre"
                @info="retourCylindre"
                height="40px"
                radius="4.68802px"
                class="mt-3"
              />
            </div>

            <div class="w-1/6 ml-4">
              <div class="text-c14 text-82">
                AXE
              </div>
              <inputo
                type="number"
                @info="retourAxe"
                :value-t="response.axe"
                background="#FBFBFB"
                height="40px"
                radius="4.68802px"
                class="mt-3"
              />
            </div>

            <div class="w-1/6 ml-4">
              <div class="text-c14 text-82">
                ADD
              </div>
              <inputo
                type="number"
                background="#FBFBFB"
                :value-t="response.addition"
                @info="retourAddition"
                height="40px"
                radius="4.68802px"
                class="mt-3"
              />
            </div>

            <div class="w-1/4 ml-4">
              <div class="text-c14 text-82">
                Fabrication
              </div>
              <multiselect
                :option="optionFabriq"
                :value-t="response.fabrication"
                size="14px"
                @info="retourFabriq"
                background="#FBFBFB"
                height="40px"
                radius="4.68802px"
                class="mt-3"
              />
            </div>

            <div class="w-1/5 ml-4">
              <div class="text-c14 text-82">
                Famille
              </div>
              <multiselect
                :option="optionFam"
                :value-t="response.famille"
                size="14px"
                @info="retourFam"
                background="#FBFBFB"
                height="40px"
                radius="4.68802px"
                class="mt-3"
              />
            </div>
          </div>

          <div class="flex mt-10">
            <div class="w-1/6">
              <div class="text-c14 text-82">
                SC
              </div>
              <multiselect
                :option="optionSc"
                :value-t="response.sousCouche"
                size="14px"
                @info="retourSc"
                background="#FBFBFB"
                height="40px"
                radius="4.68802px"
                class="mt-3"
              />
            </div>

            <div class="w-1/4 ml-4">
              <div class="text-c14 text-82">
                T. DUR
              </div>
              <multiselect
                :option="optionDur"
                :value-t="response.dur"
                @info="retourDur"
                size="14px"
                background="#FBFBFB"
                height="40px"
                radius="4.68802px"
                class="mt-3"
              />
            </div>

            <div class="w-1/5 ml-4">
              <div class="text-c14 text-82">
                COULEUR
              </div>
              <multiselect
                :option="optionColor"
                :value-t="valueCol"
                @info="retourColor"
                size="14px"
                background="#FBFBFB"
                height="40px"
                radius="4.68802px"
                class="mt-3"
              />
            </div>

            <div class="w-1/6 ml-4">
              <div class="text-c14 text-82">
                T. AR
              </div>
              <multiselect
                :option="optionAr"
                :value-t="response.ar"
                @info="retourAr"
                size="14px"
                background="#FBFBFB"
                height="40px"
                radius="4.68802px"
                class="mt-3"
              />
            </div>

            <div class="w-1/6 ml-4">
              <div class="text-c14 text-82">
                DIAMETRE
              </div>
              <inputo
                type="number"
                background="#FBFBFB"
                :value-t="response.diametre"
                @info="retourDiametre"
                height="40px"
                radius="4.68802px"
                class="mt-3"
              />
            </div>
          </div>



          <div>
            <div class="w-full mt-6">
              <inputo
                :icon="icons.search"
                @info="retourSearch"
                placeholder="Rechercher le nom d'un verre"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="w-1/4 bordLeft ml-6 pl-6 flex items-center">
        <div class="w-full">
          <bouton
            label="Rechercher un verre"
            height="40px"
            radius="5px"
            size="14px"
            weight="600"
            @oga="rechercher"
          />
          <bouton
            :icon="icons.load"
            label="Effacer tout"
            height="40px"
            color="#5138EE"
            background="#E5E1FC"
            radius="5px"
            size="14px"
            weight="600"
            class="mt-6"
            @oga="deleteAll"
          />

          <div class="mt-10 text-c18 text-center">
            <span class="text-c34 font-c7">{{ totalVerre }}</span> Verres
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 bloc p-4">
      <liste
        :type="type"
        :search="searchOption"
        :recherche="recherche"
        @totalVerre="retourCount"
        @oga="retourListe"
        @alert="retourAlert"
        @fin="retourReset"
      />
    </div>
    {{ sendSearch }}
  </div>
</template>

<script>
import eye from '../../../assets/icons/eyeBlue.svg'
import load from '../../../assets/icons/load.svg'
import down from '../../../assets/icons/down.svg'
import open from '../../../assets/icons/open.svg'
import inputo from '../../helper/form/input'
import multiselect from '../../helper/form/multiselect'
import liste from './verresListe'
import bouton from '../../helper/add/button'
import search from '../../../assets/icons/search.svg'

export default {
  name: "Index",

  components: {
    inputo,
    multiselect,
    liste,
    bouton
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },

    type: {
      type: String,
      default: null
    },

    searchData: {
      type: Object,
      default: null
    },
  },

  data () {
    return {
      icons: {
        eye,
        down,
        open,
        load,
        search
      },
      optionDur: ['0', 'BLUMAX', 'TRANSITION 7', 'TRANSITION 8', 'COLORMATIC', 'SUNLITE', 'SUNLITE-BLUMAX', 'XTRACTIVE'],
      open: false,
      optionAr: ['0', 'HC', 'HMC', 'SATIN+', 'HMCBLUE'],
      optionFam: ['Progressif', 'Dégressif', 'Double foyer', 'Simple Foyer'],
      response: {},
      optionFabriq: [ 'TRADITIONELLE', 'NUMÉRIQUE', 'FREE FORME'],
      searchOption: null,
      optionSc: ['Durci'],
      optionColor: ['GRIS', 'BRUN', 'ROSE', 'VIOLET', 'BLEU', 'VERT', 'ORANGE', 'ROUGE', 'AUCUNE .'],
      valueCol: 'AUCUNE .',
      recherche: null,
      totalVerre: 0
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendSearch: function () {
      if (this.searchOption !== null) {
        // this.$store.dispatch('saveFiltre', this.searchOption)
      }
    }
  },

  created () {
    // Reconduire les infos de filtrage
    // const filtre = this.$store.getters.filtre
    const filtre = null

    if (filtre !== null) {
      if (filtre.color !== ''){
        this.valueCol = filtre.color
      }
      this.response = filtre
    }
  },

  methods: {

    retourCount (answer) {
      this.totalVerre = answer
    },

    controle (index) {
      const panier = this.$store.getters.trackBasket
      let result = false
      for (let item in panier) {
        if (panier[item].oeil !== undefined) {
          if (panier[item].oeil === index) {
            result = true
          }
        }
      }
      return result
    },

    retourAlert (answer) {
      this.$emit('alert', answer)
    },

    deleteAll () {
      this.response = null
      setTimeout(()=>{
        this.response = {}
      },1000);
      // window.location.reload()
      this.$store.dispatch('saveFiltre', null)
      this.searchOption = null
      // console.log(this.$store.getters.filtre)
      this.open = false
    },

    retourReset (answer) {
      this.searchOption = null
      // this.$store.dispatch('saveFiltre', search)
      console.log(answer)
      this.recherche = ''
    },

    retourListe () {
      this.$emit('oga', true)
    },

    rechercher () {
      this.searchOption = this.response
      this.$store.dispatch('saveFiltre', this.searchOption)
    },

    retourSearch (answer) {
      this.recherche = answer
    },
    retourFabriq (answer) {
      this.response.fabrication = answer
    },
    retourFam (answer) {
      this.response.famille = answer
    },
    retourDur (answer) {
      this.response.dur = answer
    },
    retourAr (answer) {
      this.response.ar = answer
    },
    retourSc (answer) {
      this.response.sousCouche = answer
    },
    retourSphere (answer) {
      this.response.sphere = answer
    },
    retourAxe (answer) {
      this.response.axe = answer
    },

    retourColor (answer) {
      if (answer === 'AUCUNE .') {
        this.response.color = ''
      } else {
        this.response.color = answer
      }
    },

    retourAddition (answer) {
      this.response.addition = answer
    },
    retourCylindre (answer) {
      this.response.cylindre = answer
    },
    retourDiametre (answer) {
      this.response.diametre = answer
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: transparent;
}
.diviser{
  height: 2px;
  background-color: #DCDCE4;
}
.bloc{
  background: #FFFFFF;
  border: 0.468802px solid #CCCFD2;
  box-sizing: border-box;
  border-radius: 4.68802px;
}
.bordLeft{
  border-left: 0.468802px solid #CCCFD2;
}
</style>

<template>
  <div class="text-left">
    <div class="font-c7 text-c18">Articles sélectionnés</div>
    <div class="diviser mt-2" />

    <div class="text-90 text-center mt-10 pb-4" v-if="sendValue.length === 0"> Aucun article ajouté à ce panier</div>

    <div v-for="(i, index) in sendValue" :key="index">
      <div class="flex mt-5" v-if="i.product !== undefined && i.product.type === 'GLASS'">
        <icon
            :data="icons.verre"
            height="40"
            width="40"
            class="mr-2 cursor-pointer"
            original
        />

        <div class="ml-4 w-4/5">
          <div class="text-c14 font-c5">{{ i.product.glassType }} | {{ i.product.ref }}</div>

          <div class="flex text-c10 mt-1" v-if="i.details !== undefined">
            <span class="font-c7">{{ i.oeil }}: </span>
            <span class="font-c4 text-90 ml-2" v-if="i.details !== null && i.details !== undefined">
              Mat = {{ i.product.glassMaterial }}; Indice = {{ i.product.indice }}; S/couche = {{ i.details.sousCouche }};
              T.Dur = {{ i.details.dur }}; T.AR = {{ i.details.ar }};
              Sph = {{ i.details.sphere }}; Cyl = {{ i.details.cylindre }}; Add = {{ i.details.addition }};
              Axe = {{ i.details.axe }}°; Diamètre = {{ i.details.diametre }};
              <span v-if="i.details.color !== undefined && i.details.color !== ''">Couleur = {{ i.details.color }}</span>
            </span>
          </div>

<!--          option update -->
          <div class="flex text-c10 mt-1" v-if="i.details === undefined">
            <span class="font-c7" v-if="i.meta !== undefined"> {{ i.meta.oeil }}: </span>
            <span
                class="font-c4 text-c11 text-90"
                v-if="i.meta === undefined && updater.meta !== null && updater.meta !== undefined && updater.meta.treatmentData !== undefined && updater.meta.treatmentData !== null"
            >
                Mat = {{ i.product.glassMaterial }}; Indice = {{ i.product.indice }}; S/couche = {{ updater.meta.treatmentData.sousCouche }};
                Traitmt dur = {{ updater.meta.treatmentData.dur }}; Traitmt AR = {{ updater.meta.treatmentData.ar }};
                Sph = {{ updater.meta.treatmentData.sphere }}; Cyl = {{ updater.meta.treatmentData.sphere }}; Add = {{ updater.meta.treatmentData.addition }};
                Axe = {{ updater.meta.treatmentData.axe }}°; Diamètre = {{ updater.meta.treatmentData.diametre }};

                <span v-if="updater.meta.treatmentData.color !== undefined && updater.meta.treatmentData.color !== ''">
                  Couleur = {{ updater.meta.treatmentData.color }}
                </span>
              </span>

            <span
                class="font-c4 text-c11 text-90 ml-2"
                v-if="i.meta !== undefined && i.meta.treatmentData !== undefined && i.meta.treatmentData !== null"
            >
                Mat = {{ i.product.glassMaterial }}; Indice = {{ i.product.indice }}; S/couche = {{ i.meta.treatmentData.sousCouche }};
                Traitmt dur = {{ i.meta.treatmentData.dur }}; Traitmt AR = {{ i.meta.treatmentData.ar }};
                Sph = {{ i.meta.treatmentData.sphere }}; Cyl = {{ i.meta.treatmentData.sphere }}; Add = {{ i.meta.treatmentData.addition }};
                Axe = {{ i.meta.treatmentData.axe }}°; Diamètre = {{ i.meta.treatmentData.diametre }};

                <span v-if="i.meta.treatmentData.color !== undefined && i.meta.treatmentData.color !== ''">
                  Couleur = {{ i.meta.treatmentData.color }}
                </span>
              </span>
          </div>

          <div class="text-c12 text-90" v-if="i.prix !== undefined">X 1 ({{ i.prix.toLocaleString() }} FCFA)</div>
<!--update session-->
          <div class="text-c12 text-90" v-if="i.prix === undefined">X 1 ({{ i.amount.toLocaleString() }} FCFA)</div>
        </div>

        <icon
            :data="icons.remove"
            height="20"
            width="20"
            class="ml-2 cursor-pointer"
            @click="remove(index)"
            original
        />
      </div>

      <div class="flex mt-5 items-center" v-if="i.type === 'FRAMES'">
        <icon
            :data="icons.monture"
            height="40"
            width="40"
            class="mr-2 cursor-pointer"
            original
        />

        <div class="ml-4 w-4/5">
          <div class="text-c14 font-c5"> {{ i.name }} </div>
          <div class="text-c12 text-90" v-if="i.prix !== undefined">X {{ i.qte }} ({{ i.prix.toLocaleString() }} FCFA)</div>
          <div class="text-c12 text-90" v-if="i.prix === undefined">X 1 ({{ i.amount.toLocaleString() }} FCFA)</div>
        </div>

        <icon
            :data="icons.remove"
            height="20"
            width="20"
            class="ml-2 cursor-pointer"
            @click="remove(index)"
            original
        />
      </div>

      <div class="flex mt-5 items-center" v-if="i.type === 'ACCESSORY'">
        <icon
            :data="icons.etuie"
            height="40"
            width="40"
            class="mr-2 cursor-pointer"
            original
        />

        <div class="ml-4 w-4/5">
          <div class="text-c14 font-c5">{{ i.name }}</div>
          <div class="text-c12 text-90" v-if="i.prix !== undefined">X {{ i.qte }} ({{ i.prix.toLocaleString() }} FCFA)</div>
          <div class="text-c12 text-90" v-if="i.prix === undefined">X 1 ({{ i.amount.toLocaleString() }} FCFA)</div>
        </div>

        <icon
            :data="icons.remove"
            height="20"
            width="20"
            class="ml-2 cursor-pointer"
            @click="remove(index)"
            original
        />
      </div>

      <div class="flex mt-5 items-center" v-if="i.type === 'SERVICE' || i.type === 'TREATMENT'">
        <icon
            :data="icons.prestation"
            height="40"
            width="40"
            class="mr-2 cursor-pointer"
            v-if="i.type === 'SERVICE'"
            original
        />

        <icon
            :data="icons.traitement"
            height="40"
            width="40"
            class="mr-2 cursor-pointer"
            v-if="i.type === 'TREATMENT'"
            original
        />

        <div class="ml-4 w-4/5">
          <div class="text-c14 font-c5">{{ i.name }}</div>
          <div class="text-c12 text-90" v-if="i.prix !== undefined">X {{ i.qte }} ({{ i.prix.toLocaleString() }} FCFA)</div>
          <div class="text-c12 text-90" v-if="i.prix === undefined">X 1 ({{ i.amount.toLocaleString() }} FCFA)</div>
        </div>

        <icon
            :data="icons.remove"
            height="20"
            width="20"
            class="ml-2 cursor-pointer"
            @click="remove(index)"
            original
        />
      </div>

      <div class="diviser mt-5" v-if="index + 1 < sendValue.length"/>
    </div>

    <diviser height="4px" color="#D6DDE2" class="mt-5" v-if="sendValue.length !== 0"/>

    <div class="mt-8" v-if="sendValue.length !== 0">
      <div class="flex">
        <div class="w-1/2 text-c16 text-90">Articles</div>
        <div class="w-1/2 text-c18 font-c7">{{ sendValue.length }}</div>
      </div>

      <div class="flex mt-4">
        <div class="w-1/2 text-c16 text-90">Montant total</div>
        <div class="w-1/2 text-c18 font-c7">{{ amount.toLocaleString() }} Fr</div>
      </div>
    </div>

    <bouton label="Ajouter au devis" height="44.44px" @oga="saveQuote" radius="5px" size="16px" class="mt-10" v-if="sendValue.length !== 0"/>
    <bouton label="Vider mon panier" height="44.44px" @oga="removeAll" radius="5px" size="16px" class="mt-3" background="#EAEAEF" color="black" v-if="sendValue.length !== 0"/>
  </div>
</template>

<script>
import verre from '../../../assets/icons/verre.svg'
import prestation from '../../../assets/icons/prestation.svg'
import monture from '../../../assets/icons/monture.svg'
import traitement from '../../../assets/icons/surface.svg'
import etuie from '../../../assets/icons/etuie.svg'
import remove from '../../../assets/icons/remove.svg'
import diviser from '../../helper/add/divider'
import bouton from '../../helper/add/button'

export default {
  name: "Index",

  components: {
    diviser,
    bouton
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },
    remover: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      icons: {
        verre,
        remove,
        monture,
        etuie,
        prestation,
        traitement
      },
      updater: null,
      position: -1,
      temoin: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.remover !== this.temoin) {
        this.finaleRemove()
      }
      return this.$store.getters.trackBasket
    },

    amount: function () {
      let data = this.$store.getters.trackBasket
      let amount = 0
      for (let o in data) {
        if (data[o].prix !== undefined) {
          amount = amount + (parseInt(data[o].prix) * parseInt(data[o].qte))
        } else {
          // update session
          amount = amount + (parseInt(data[o].amount) * parseInt(data[o].quantity))
        }
      }
      return amount
    }
  },

  created () {
    this.updater = this.$store.getters.update
  },

  methods: {
    finaleRemove () {
      let data = this.$store.getters.trackBasket
      data.splice(this.position, 1)
      this.$store.dispatch('saveBasket', data)
      this.temoin = this.remover
    },

    remove (index) {
      this.position = index
      this.$emit('oga', true)
    },

    saveQuote(){
      let devis = this.$store.getters.trackQuote
      if (this.updater === null) {
        devis.panier = this.$store.getters.trackBasket
        this.$store.dispatch('saveQuote', devis)
        this.$router.push('/nouveauDevis')
      } else {
        this.updater.articles = this.$store.getters.trackBasket
        this.$store.dispatch('saveUpdate', this.updater)
        this.$router.push('/modifierDevis')
      }

    },

    removeAll () {
      this.$store.dispatch('saveBasket', [])
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: transparent;
}
.diviser{
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>

<template>
  <div>
    <div class="text-left bloc">
      <div class="flex items-center bg-ec rounded-5 p-3 cursor-pointer" @click="openBox">
        <icon
            :data="icons.eye"
            height="30"
            width="30"
            class="mr-2 "
            original
        />

        <div class="w-full ml-2 text-30 text-c16 font-c6">
          <span v-if="type === 'OD'">OEIL DROIT (OD)</span>
          <span v-if="type === 'OG'">OEIL GAUCHE (OG)</span>
        </div>

        <icon
            :data="icons.down"
            height="15"
            width="15"
            class="cursor-pointer"
            v-if="!open"
            original
        />
        <icon
            :data="icons.open"
            height="15"
            width="15"
            class="cursor-pointer"
            v-if="open"
            original
        />
      </div>

      <div v-if="open && controle(type) === false">
        <filtre :type="type" @oga="retourOeil" @alert="retourAlert"/>
      </div>
    </div>
  </div>
</template>

<script>
import eye from '../../../assets/icons/eyeBlue.svg'
import load from '../../../assets/icons/load.svg'
import down from '../../../assets/icons/down.svg'
import open from '../../../assets/icons/open.svg'
import search from '../../../assets/icons/search.svg'
import filtre from './filtre'


export default {
  name: "Index",

  components: {
    filtre
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },

    type: {
      type: String,
      default: null
    },

    searchData: {
      type: Object,
      default: null
    },
  },

  data () {
    return {
      icons: {
        eye,
        down,
        open,
        load,
        search
      },
      optionDur: ['0', 'BLUMAX', 'TRANSITION 7', 'TRANSITION 8', 'COLORMATIC', 'SUNLITE', 'SUNLITE-BLUMAX', 'XTRACTIVE'],
      open: false,
      optionAr: ['0', 'HC', 'HMC', 'SATIN+'],
      optionFam: ['Progressif', 'Dégressif', 'Double foyer', 'Simple Foyer'],
      response: {},
      optionFabriq: [ 'TRADITIONELLE', 'NUMÉRIQUE', 'FREE FORME'],
      searchOption: null,
      optionSc: ['Durci'],
      optionColor: ['GRIS', 'BRUN', 'AUCUNE .'],
      valueCol: 'AUCUNE .',
      recherche: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendSearch: function () {
      if (this.searchOption !== null) {
        // this.$store.dispatch('saveFiltre', this.searchOption)
      }
    }
  },

  created () {
  },

  methods: {
    openBox(){
      this.open =! this.open
    },

    controle (index) {
      const panier = this.$store.getters.trackBasket
      let result = false
      for (let item in panier) {
        if (panier[item].oeil !== undefined) {
          if (panier[item].oeil === index) {
            result = true
          }
        }
      }
      return result
    },

    retourOeil () {
      this.$emit('oga', true)
    },

    retourAlert (answer) {
      this.$emit('alert', answer)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: transparent;
}
.diviser{
  height: 2px;
  background-color: #DCDCE4;
}
.bloc{
  background: #FFFFFF;
  border: 0.468802px solid #CCCFD2;
  box-sizing: border-box;
  border-radius: 4.68802px;
}
.bordLeft{
  border-left: 0.468802px solid #CCCFD2;
}
</style>

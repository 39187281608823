<template>
  <div class="pt-6">
    <div class="text-c18 text-left">
      <span class="text-c34 font-c7 mr-2">{{ tracker.length }}</span> Accessoires
    </div>

    <div class="flex mt-6">
      <div class="w-3/5 flex">
        <div class="w-1/4">
          <multiselect :option="optionMarque" v-if="optionMarque.length > 0" @info="retourMarque" value-t="Marques" size="13px"/>
        </div>

        <div class="w-2/4 ml-6">
          <inputo :icon="icons.search" @info="retourSearch" placeholder="Rechercher" />
        </div>
      </div>
    </div>

    <div class="flex justify-center mt-12" v-if="charge && allVerre.length === 0">
      <easy-spinner
          type="dots"
          size="30"
      />
    </div>

    <div class="mt-10 text-center text-c18 text-60" v-if="tracker.length === 0">Aucun accessoire disponible</div>

    <div
      v-for="(item, index) in tracker"
      :key="index"
      class="mt-10"
      @click="selected(item)"
    >
      <div class="">
        <div class="w-full flex text-c14 font-c4 text-left items-center item cursor-pointer">
          <div class="w-1/2 flex items-center">
            <img
                src="../../../assets/images/jpg/lunette.jpg"
                alt=""
                class="w-1/6"
            >
            <div
                class="ml-6"
            >
              <div class="text-c15 font-c6 tit">
                {{item.name}}
              </div>

              <div class="text-c11 mt-2">
                <span class="text-90">Qté dispo :</span> <span> {{ item.quantity.toLocaleString() }} </span>

                <span v-for="(i, index) in item.prices" :key="index">
              <span class="ml-4"> {{i.amount}} Fr </span>
              <span class="text-90 ml-2 mr-2"> |</span>
            </span>
              </div>
            </div>
          </div>

          <div class="w-1/5 ml-2 text-left">
            {{ item.brands[0].label }}
          </div>

          <div class="w-1/5 ml-2 text-left" >
            <span v-if="item.ranges[0]">{{ item.ranges[0].label }}</span>
          </div>

          <div class="w-48 ml-2">
            <icon
                :data="icons.add"
                height="30"
                width="30"
                class="icone cursor-pointer"
                original
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import add from '../../../assets/icons/addGreen.svg'
import search from '../../../assets/icons/search.svg'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"
import multiselect from '../../helper/form/multiselect'
import inputo from '../../helper/form/input'

export default {
  name: "Index",
  components: {
    multiselect,
    inputo
  },

  data () {
    return {
      icons: {
        add,
        search
      },
      allVerre: [],
      charge: true,
      optionMarque: [],
      marqueValue: '',
      selectedItem: {},
      search: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.allVerre
      if (this.search !== null && this.search !== '') {
        const type = this.search.toLowerCase()
        retour = retour.filter((item) => {
          if ((item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
            return item
          }
        })
      }
      //Marque
      if (this.marqueValue !== null && this.marqueValue !== 'Marques' && this.marqueValue !== 'Toutes ...') {
        retour = retour.filter((item) => {
          for(let i in item.brands) {
            if (item.brands[i].label === this.marqueValue) {
              return item
            }
          }

        })
      }

      return retour
    }
  },

  created () {
    this.allProduct()
    this.allMarques()
  },

  methods: {
    allProduct () {
      http.get(apiroutes.baseURL + apiroutes.getProduct + '?type=ACCESSORY')
          .then(response => {
            // console.log(response)
            this.allVerre = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourMarque (answer) {
      if (answer !== '' && answer !== null){
        this.marqueValue = answer
      }
    },

    retourSearch (answer) {
      this.search = answer
    },

    allMarques () {
      http.get(apiroutes.baseURL + apiroutes.allMarque)
          .then(response => {
            let tab = ['Toutes ...']
            for (let item in response) {
              tab.push(response[item].label)
            }
            this.optionMarque = tab
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    selected (item) {
      this.selectedItem = item
      this.$store.dispatch('saveTemporalItem', this.selectedItem)
      this.$emit('oga', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  .tit {
    color: #3521B5;
  }
}
</style>

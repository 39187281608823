<template>
  <div>
    <oeil-template type="OD" @oga="retourOeil" @alert="retourAlert"/>
    <oeil-template type="OG" class="mt-10" @oga="retourOeil" @alert="retourAlert"/>

    <traitementOpen @oga="retourOeil" @alert="retourAlert" class="mt-10"/>
  </div>
</template>

<script>
import oeilTemplate from './oeilTemplate'
import traitementOpen from './traitementOpen'

export default {
  name: "Index",

  components: {
    oeilTemplate,
    traitementOpen
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      filtre: null
    }
  },

  created () {
  },

  methods: {
    retourOeil () {
      this.$emit('oga', true)
    },

    retourAlert (answer) {
      this.$emit('alert', answer)
    }

  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: transparent;
}
</style>

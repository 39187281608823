<template>
  <div>
    <div class="text-left bloc">
      <div class="flex items-center bg-ec rounded-5 p-3 cursor-pointer" @click="openBox">
        <icon
            :data="icons.eye"
            height="30"
            width="30"
            class="mr-2 "
            original
        />

        <div class="w-full ml-2 text-30 text-c16 font-c6">
          TRAITEMENTS ADDITIONNELS
        </div>

        <icon
            :data="icons.down"
            height="15"
            width="15"
            class="cursor-pointer"
            v-if="!active"
            original
        />
        <icon
            :data="icons.open"
            height="15"
            width="15"
            class="cursor-pointer"
            v-if="active"
            original
        />
      </div>

      <div v-if="active">
        <div class="mt-10 flex">
          <steep
              :option="allSteep"
              :value="valueSteep"
              :separator="2"
              @info="retourSteep"
          />
        </div>

        <treatment type="MASS" v-if="valueSteep ===  'Traitement additionnel de masse'"/>
        <treatment type="AREA" v-if="valueSteep ===  'Traitement additionnel de surface'"/>
      </div>
    </div>
  </div>
</template>

<script>
import eye from '../../../assets/icons/eyeBlue.svg'
import load from '../../../assets/icons/load.svg'
import down from '../../../assets/icons/down.svg'
import open from '../../../assets/icons/open.svg'
import search from '../../../assets/icons/search.svg'
import treatment from './treatment'
import steep from '../../helper/form/steepSelect'



export default {
  name: "Index",

  components: {
    treatment,
    steep
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },

    type: {
      type: String,
      default: null
    },

    searchData: {
      type: Object,
      default: null
    },
  },

  data () {
    return {
      icons: {
        eye,
        down,
        open,
        load,
        search
      },
      active: false,
      allSteep: ['Traitement additionnel de masse', 'Traitement additionnel de surface'],
      valueSteep: 'Traitement additionnel de masse',
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendSearch: function () {
      if (this.searchOption !== null) {
        // this.$store.dispatch('saveFiltre', this.searchOption)
      }
    }
  },

  created () {
  },

  methods: {
    openBox(){
      this.active =! this.active
    },

    retourOeil () {
      this.$emit('oga', true)
    },

    retourAlert (answer) {
      this.$emit('alert', answer)
    },

    retourSteep (answer) {
      this.valueSteep = answer
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: transparent;
}
.diviser{
  height: 2px;
  background-color: #DCDCE4;
}
.bloc{
  background: #FFFFFF;
  border: 0.468802px solid #CCCFD2;
  box-sizing: border-box;
  border-radius: 4.68802px;
}
.bordLeft{
  border-left: 0.468802px solid #CCCFD2;
}
</style>
